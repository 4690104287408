<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <ApeMenu :menus="menus"/>
            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'inquiriesIndex',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      menus: [
        {
          icon: 'quanxundan',
          display_name: '普通询单',
          url: '/inquiries/list',
          permission_name: 'inquiry_owner_projects'
        },
        {
          icon: 'woxundan',
          display_name: '年框大全案',
          url: '/inquiries/YearFrame',
          permission_name: 'inquiry_owner_projects'
        },
        // {
        //   icon: 'baoming',
        //   display_name: '报名列表',
        //   url: '/inquiries/applylist',
        //   permission_name: 'inquiry_apply_manage'
        // }
        // ,
        // //   /charging_standard/save
        // {
        //   icon: 'woxundan',
        //   display_name: '授权',
        //   url: '/inquiries/chargingStandard',
        //   permission_name: 'inquiry_auth_setting'
        // }
      ]
    }
  }
}
</script>

<style scoped></style>
